import * as React from 'react';
import AuthContext from '../AuthContext';
import { doSignOut } from '../utils/reducers';

function useSignOut(): () => boolean {
  /**
   *A constant c.
   *@kind constant
   */
  const context = React.useContext(AuthContext);
  if (context === null) {
    throw new Error(
      'Auth Provider is missing. ' + 'Please add the AuthProvider before Router'
    );
  }

  return () => {
    try {
      if (context) {
        context.dispatch(doSignOut());
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };
}

export default useSignOut;
