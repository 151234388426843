import { createContext, FC, useContext, useReducer } from 'react';

type Region = 'US' | 'AU';

type Action =
  | { type: 'setPageTitle'; payload: { value: string } }
  | { type: 'setPageTitleComponent'; payload: { value: React.FC } }
  | { type: 'setRegion'; payload: { value: Region } };

type State = {
  pageTitle: React.FC;
  region: Region;
};

type Dispatch = (action: Action) => void;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setPageTitle': {
      return {
        ...state,
        pageTitle: () => <>{action.payload.value}</>,
      };
    }
    case 'setPageTitleComponent': {
      return {
        ...state,
        pageTitle: action.payload.value,
      };
    }
    case 'setRegion': {
      localStorage.setItem('region', action.payload.value);
      return {
        ...state,
        region: action.payload.value,
      };
    }
  }
}

export function useAppContext(): [State, Dispatch] {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  if (state === undefined || dispatch === undefined) {
    throw new Error('useAppContext must be used within a ContextProvider');
  }
  return [state, dispatch];
}

export function setPageTitle(dispatch: Dispatch, value: string) {
  dispatch({ type: 'setPageTitle', payload: { value } });
}

export function setPageTitleComponent(dispatch: Dispatch, value: React.FC) {
  dispatch({ type: 'setPageTitleComponent', payload: { value } });
}

export function setRegion(dispatch: Dispatch, value: Region) {
  dispatch({ type: 'setRegion', payload: { value } });
}

const StateContext = createContext<State | undefined>(undefined);
const DispatchContext = createContext<Dispatch | undefined>(undefined);

export const ContextProvider: FC<{ init?: Partial<State> }> = ({
  children,
  init,
}) => {
  const initialState: State = {
    pageTitle: () => <>{'Home'}</>,
    region: (localStorage.getItem('region') as Region) ?? 'AU',
    ...init,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};
