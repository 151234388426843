import { GraphQLClient } from "graphql-request"
import { QueryClientProvider, QueryClient } from "react-query";
import GraphqlClientContext from "./GraphqlClientContext";

interface Props {
    configs: {
        key: string;
        endpoint: string;
    }[]
}

export const GraphqlClientContextProvider: React.FunctionComponent<Props> =
({ children, configs }) => {
    const m = new Map<string, [string, GraphQLClient]>();
    for (const { key, endpoint } of configs) {
        m.set(key, [endpoint, new GraphQLClient(endpoint)]);
    }
    const queryClient = new QueryClient();
    return (
        <QueryClientProvider client={queryClient}>
            <GraphqlClientContext.Provider value={ m }>
                { children }
            </GraphqlClientContext.Provider>
        </QueryClientProvider>
    );
}