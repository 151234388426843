import { TypographyOptions } from "@mui/material/styles/createTypography";

export const typography: TypographyOptions = {
  allVariants: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 400,
    fontSize: '1rem'
  },
  h3: {
    fontWeight: 700,
  },
  h4: {
    fontSize: "1.75rem",
    fontWeight: 600,
  },
  h5: {
    fontWeight: 600,
  },
  h6: {
    fontWeight: 700,
    fontSize: '2.25rem',
  }
};
