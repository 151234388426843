import { AuthKitStateInterface, AuthStateUserObject } from './types';

/**
 * @class TokenObject
 *
 * Stores and retrieve Token
 */
class TokenObject {
  private readonly authStorageName: string;
  private readonly stateStorageName: string;
  private readonly authTimeStorageName: string;
  private readonly authStorageTypeName: string;
  private readonly refreshTokenName: string | null;
  private readonly refreshTokenTimeName: string | null;
  private readonly isUsingRefreshToken: boolean;

  constructor(
    authStorageName: string,
    refreshTokenName: string | null
  ) {
    this.authStorageName = authStorageName;
    this.authTimeStorageName = `${authStorageName}_storage`;
    this.stateStorageName = `${authStorageName}_state`;
    this.refreshTokenName = refreshTokenName;
    this.authStorageTypeName = `${this.authStorageName}_type`;
    this.isUsingRefreshToken = !!this.refreshTokenName;
    this.refreshTokenTimeName = this.refreshTokenName
      ? `${this.refreshTokenName}_time`
      : null;
  }

  initialToken(): AuthKitStateInterface {
    return this.initialLSToken_();
  }

  initialLSToken_(): AuthKitStateInterface {
    const authToken = localStorage.getItem(this.authStorageName);
    const authTokenType = localStorage.getItem(this.authStorageTypeName);
    const authTokenTime = localStorage.getItem(this.authTimeStorageName);
    const stateCookie = localStorage.getItem(this.stateStorageName);

    const refreshToken =
      this.isUsingRefreshToken && this.refreshTokenName != null
        ? localStorage.getItem(this.refreshTokenName)
        : null;

    const refreshTokenTime =
      this.isUsingRefreshToken && this.refreshTokenTimeName != null
        ? localStorage.getItem(this.refreshTokenTimeName)
        : null;

    return this.checkTokenExist(
      authToken,
      authTokenType,
      authTokenTime,
      stateCookie,
      refreshToken,
      refreshTokenTime
    );
  }

  checkTokenExist(
    authToken: string | null | undefined,
    authTokenType: string | null | undefined,
    authTokenTime: string | null | undefined,
    stateCookie: string | null | undefined,
    refreshToken: string | null | undefined,
    refreshTokenTime: string | null | undefined
  ): AuthKitStateInterface {
    if (!!authToken && !!authTokenType && !!authTokenTime && !!stateCookie) {
      const expiresAt = new Date(authTokenTime);
      try {
        const authState = JSON.parse(stateCookie);
        const obj = {
          auth: {
            token: authToken,
            type: authTokenType,
            expiresAt: expiresAt,
          },
          userState: authState,
          isSignIn: true,
          isUsingRefreshToken: this.isUsingRefreshToken,
          refresh: undefined,
        };
        if (this.isUsingRefreshToken && !!refreshToken && !!refreshTokenTime) {
          const refreshTokenExpiresAt = new Date(refreshTokenTime);
          return {
            ...obj,
            refresh: {
              token: refreshToken,
              expiresAt: refreshTokenExpiresAt,
            },
          };
        } else {
          return {
            ...obj,
            refresh: null,
          };
        }
      } catch (e) {
        return {
          auth: null,
          refresh: null,
          userState: null,
          isUsingRefreshToken: this.isUsingRefreshToken,
          isSignIn: false,
        };
      }
    } else {
      return {
        auth: null,
        refresh: null,
        userState: null,
        isUsingRefreshToken: this.isUsingRefreshToken,
        isSignIn: false,
      };
    }
  }

  syncTokens(authState: AuthKitStateInterface): void {
    if (authState.auth) {
      if (this.isUsingRefreshToken && authState.refresh) {
        this.setToken(
          authState.auth.token,
          authState.auth.type,
          authState.refresh.token,
          authState.refresh.expiresAt,
          authState.auth.expiresAt,
          authState.userState
        );
      } else {
        this.setToken(
          authState.auth.token,
          authState.auth.type,
          null,
          null,
          authState.auth.expiresAt,
          authState.userState
        );
      }
    } else {
      this.removeToken();
    }
  }

  setToken(
    authToken: string,
    authTokenType: string,
    refreshToken: string | null,
    refreshTokenExpiresAt: Date | null,
    expiresAt: Date,
    authState: AuthStateUserObject | null
  ): void {
    this.setLSToken_(
      authToken,
      authTokenType,
      refreshToken,
      expiresAt,
      refreshTokenExpiresAt,
      authState
    );
  }
  setLSToken_(
    authToken: string,
    authTokenType: string,
    refreshToken: string | null,
    expiresAt: Date,
    refreshTokenExpiresAt: Date | null,
    authState: AuthStateUserObject | null
  ): void {
    localStorage.setItem(this.authStorageName, authToken);
    localStorage.setItem(this.authStorageTypeName, authTokenType);
    localStorage.setItem(this.authTimeStorageName, expiresAt.toISOString());
    if (authState) {
      localStorage.setItem(this.stateStorageName, JSON.stringify(authState));
    }
    if (this.isUsingRefreshToken && !!this.refreshTokenName && !!refreshToken) {
      localStorage.setItem(this.refreshTokenName, refreshToken);
    }
    if (
      this.isUsingRefreshToken &&
      !!this.refreshTokenTimeName &&
      !!refreshTokenExpiresAt
    ) {
      localStorage.setItem(
        this.refreshTokenTimeName,
        refreshTokenExpiresAt.toISOString()
      );
    }
  }

  /**
   * Remove Tokens on time of Logout
   */
  removeToken(): void {
    this.removeLSToken_();
  }

  /**
   * Remove Token from LocalStorage
   */
  removeLSToken_(): void {
    localStorage.removeItem(this.authStorageName);
    localStorage.removeItem(this.authTimeStorageName);
    localStorage.removeItem(this.authStorageTypeName);
    localStorage.removeItem(this.stateStorageName);
    if (this.isUsingRefreshToken && !!this.refreshTokenName) {
      localStorage.removeItem(this.refreshTokenName);
    }
    if (this.isUsingRefreshToken && !!this.refreshTokenTimeName) {
      localStorage.removeItem(this.refreshTokenTimeName);
    }
  }
}

export default TokenObject;
