import { useRouter } from 'next/router';
import assert from 'assert';
import { ComponentType } from 'react';
import { v4 } from 'uuid';
import {
  createRefresh,
  AuthProvider,
  useIsAuthenticated,
  useSignIn,
} from '@bamboard/react-auth-kit';
import decode from 'jwt-decode';

const CODE_VERIFIER_KEY = '__CODE__VERIFIER__';
const STATE_KEY = '__STATE__';
const NONCE_KEY = '__NONCE__';

function b64url(base64: string) {
  return base64.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}

const PKCE_CHARSET =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';

const refresh = createRefresh({
  interval: 10,
  refreshApiCallback: async ({ refreshToken }) => {
  if (!refreshToken) {
    console.error("Cannot refresh access token because refresh token does not exist");
    return { isSuccess: false };
  }
  try {
    const response = await fetch(
      `https://${process.env.NEXT_PUBLIC_AUTH_DOMAIN}/oauth2/token`,
      {
        method: 'post',
        body: new URLSearchParams({
          grant_type: 'refresh_token',
          client_id: process.env.NEXT_PUBLIC_CLIENT_ID,
          refresh_token: refreshToken,
        }),
      }
    );

    const result = await response.json()
    assert.ok(result?.access_token, new Error("No access token from refresh flow."));

    return {
      isSuccess: true,
      newAuthToken: result.access_token,
      newAuthTokenExpireIn: (result?.expires_in / 60) ?? 0,
    };
  } catch (err) {
    console.error(err);
    return { isSuccess: false };
  }
}});

async function generateCodeChallenge() {
  const randomArray = new Uint8Array(43);
  window.crypto.getRandomValues(randomArray);

  const verifier = b64url(
    Array.from(randomArray)
      .map((num: number) => PKCE_CHARSET[num % PKCE_CHARSET.length])
      .join('')
  );

  const challenge = await window.crypto.subtle
    .digest('SHA-256', new TextEncoder().encode(verifier))
    .then((buf) => b64url(Buffer.from(buf).toString('base64')));

  return { verifier, challenge, state: v4(), nonce: v4() };
}

interface TokenResponse {
  id_token: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
}

const Token = async ({
  code,
  verifier,
}: {
  code: string;
  verifier: string;
}) => {
  const params = new URLSearchParams({
    grant_type: 'authorization_code',
    code,
    client_id: process.env.NEXT_PUBLIC_CLIENT_ID,
    redirect_uri: process.env.NEXT_PUBLIC_REDIRECT_URI,
    code_verifier: verifier,
  });

  try {
    const response = await fetch(
      `https://${process.env.NEXT_PUBLIC_AUTH_DOMAIN}/oauth2/token`,
      {
        method: 'post',
        body: params,
      }
    );
    return response.json() as Partial<TokenResponse>;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const Authorize = async () =>
  await generateCodeChallenge().then(
    ({ challenge, verifier, state, nonce }) => {
      sessionStorage.setItem(CODE_VERIFIER_KEY, verifier);
      sessionStorage.setItem(STATE_KEY, state);
      sessionStorage.setItem(NONCE_KEY, nonce);

      const params = new URLSearchParams({
        response_type: 'code',
        client_id: process.env.NEXT_PUBLIC_CLIENT_ID,
        redirect_uri: process.env.NEXT_PUBLIC_REDIRECT_URI,
        scope: process.env.NEXT_PUBLIC_SCOPE,
        nonce,
        state,
        code_challenge: challenge,
        code_challenge_method: 'S256',
      });

      window.location.replace(
        `https://${
          process.env.NEXT_PUBLIC_AUTH_DOMAIN
        }/oauth2/authorize?${params.toString()}`
      );
    }
  );

export function WithAuthProvider<P extends object>(
  WrappedComponent: ComponentType<P>
) {
  const Provided = (props: P) => {
    return (
      <AuthProvider authName={'_auth'} refresh={refresh}>
        <WrappedComponent {...props} />
      </AuthProvider>
    );
  };
  Provided.displayName = 'WithAuthProvider';
  return Provided;
}

export function Callback() {
  const router = useRouter();
  const signIn = useSignIn();
  const isAuthenticated = useIsAuthenticated();

  const code = router.query.code as string;
  const returnedState = router.query.state as string;

  if (!code || !returnedState) {
    return <></>;
  }

  if (isAuthenticated()) {
    router.push('/');
    return <></>;
  }

  const verifier = sessionStorage.getItem(CODE_VERIFIER_KEY);
  const state = sessionStorage.getItem(STATE_KEY);
  assert.ok(verifier, new Error('Code Verifier does not exist'));
  assert.ok(state, 'State does not exist');

  assert.strictEqual(
    state,
    returnedState,
    new Error('Invalid state returned from callback')
  );

  sessionStorage.removeItem(CODE_VERIFIER_KEY);
  sessionStorage.removeItem(STATE_KEY);

  Token({ code, verifier })
    .then(({ access_token, refresh_token, expires_in, id_token }) => {
      assert.ok(access_token, new Error('no access token present in response'));
      assert.ok(refresh_token, new Error('No refresh token in response'));
      assert.ok(id_token, new Error('No ID token in response'));
      assert.ok(expires_in, new Error('No expiry set'));

      const id: Record<string, unknown> = decode(id_token);

      signIn({
        token: access_token,
        tokenType: 'Bearer',
        expiresIn: expires_in,
        authState: {
          sub: (id?.sub as string) ?? undefined,
          email: (id?.email as string) ?? undefined,
          groups: (id?.['cognito:groups'] as string[]) ?? [],
        },
        refreshToken: refresh_token,
        refreshTokenExpireIn: 7 * 1440 // 7 * 1 day in minutes
      });
    })
    .catch((err) => {
      console.error(err);
      throw err;
    })
    .finally(() => {
      router.push('/');
    });

  return <></>;
}

export function WithAuthentication<P extends object>(
  WrappedComponent: ComponentType<P>
) {
  const Authenticated = (props: P) => {
    const router = useRouter();
    const isAuthenticated = useIsAuthenticated();

    const show =
      isAuthenticated() ||
      router.pathname === '/callback' ||
      window?.location?.pathname === '/callback';

    if (!show) {
      Authorize();
    }
    return <>{show ? <WrappedComponent {...props} /> : <></>}</>;
  };
  Authenticated.displayName = 'Authenticated';
  return Authenticated;
}
