import { useContext } from "react";
import GraphqlClientContext from "../GraphqlClientContext";

export function useEndpoint(): (key: string) => string {
    const c = useContext(GraphqlClientContext)
    if (c === null) {
        throw new Error('GraphQL Client Provider is missing');
    }
    return (key: string): string => {
        const value = c.get(key);
        if (!value) throw new Error("Could not retrieve client")
        return value[0];
    }
}
