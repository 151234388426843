import { GraphQLClient } from 'graphql-request';
import { useContext } from 'react';
import GraphqlClientContext from '../GraphqlClientContext';
import * as assert from 'assert';
import { useAppContext } from '../AppContext';
import { useAccessToken } from '@bamboard/react-auth-kit';

export function useClient(): GraphQLClient {
  const token = useAccessToken();
  const [{ region }] = useAppContext();
  console.log('use query client', { region, token });
  const value = useContext(GraphqlClientContext).get(region);
  assert.ok(value, new Error('Could not retrieve client'));
  const [, item] = value;
  if (token) {
    item.setHeader('authorization', `${token}`);
  }
  return item;
}
