import { responsiveFontSizes } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { colourPalette } from "./colourPalette";
import { componentOverrides } from "./componentOverrides";
import { typography } from "./typography";

const theme = createTheme({
  components: componentOverrides,
  palette: colourPalette,
  typography: typography,
});

export default responsiveFontSizes(theme, {
  factor: 2,
});
