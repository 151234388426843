import { PaletteOptions } from '@mui/material/styles';

export const COLOURS = {
  // primary: {},
  // secondary: {},
  background: {
    default: '#F3F6FF',
  },
  text: {
    primary: '#252323',
    secondary: '#63666A'
  },
  grey: {
    500: '#BCBEC1',
    600: '#898C91',
    700: '#63666A'
  },
  success: {
    main: '#259283',
  },
  error: {
    main: '#E67968',
  },
  blue: {
    light: '#4260CD',
    dark: '#001781',
  },
  backgroundColors: {
    teal: '#EAF8F6',
    citrus: '#FFF0D1',
    red: '#FFEDEA',
    sky: '#E4F6FA'
  }
};

export const colourPalette: PaletteOptions = {
  ...COLOURS,
};
