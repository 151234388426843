import * as assert from 'assert';
import * as React from 'react';
import AuthContext from '../AuthContext';

/**
 *
 */
function useAccessToken() {
  const authContext = React.useContext(AuthContext);
  assert.ok(
    authContext,
    new Error(
      'Auth Provider is missing. Please add the AuthProvider before Router'
    )
  );

  const { auth } = authContext.authState;
  assert.ok(auth, new Error('Auth state missing auth'));
  return auth.token;
}

export default useAccessToken;
