/* eslint-disable */
import * as React from 'react';
import AuthContext from './AuthContext';
import TokenObject from './TokenObject';
import { AuthProviderProps } from './types';
import { authReducer, doRefresh } from './utils/reducers';
import { useInterval } from './utils/hooks';

const AuthProvider: React.FunctionComponent<AuthProviderProps> = ({
  children,
  authName,
  refresh,
}) => {
  const refreshTokenName = refresh ? `${authName}_refresh` : null;

  const tokenObject = new TokenObject(authName, refreshTokenName);

  const [authState, dispatch] = React.useReducer(
    authReducer,
    tokenObject.initialToken()
  );

  if (refresh) {
    useInterval(
      () => {
        refresh
          .refreshApiCallback({
            authToken: authState.auth?.token,
            authTokenExpireAt: authState.auth?.expiresAt,
            authUserState: authState.userState,
            refreshToken: authState.refresh?.token,
            refreshTokenExpiresAt: authState.refresh?.expiresAt,
          })
          .then((result) => {
            // IF the API call is successful then refresh the AUTH state
            if (result.isSuccess) {
              // store the new value using the state update
              dispatch(doRefresh(result));
            }
          });
      },
      authState.isSignIn ? refresh.interval : null
    );
  }

  React.useEffect(() => {
    tokenObject.syncTokens(authState);
  }, [authState]);

  return (
    <AuthContext.Provider value={{ authState, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
