import * as React from 'react';
import AuthContext from '../AuthContext';
import { doSignOut } from '../utils/reducers';

function useIsAuthenticated(): () => boolean {
  const context = React.useContext(AuthContext);
  if (context === null) {
    throw new Error(
      'Auth Provider is missing. ' + 'Please add the AuthProvider before Router'
    );
  }
  return () => {
    if (context.authState.auth) {
      if (new Date(context.authState.auth.expiresAt) > new Date()) {
        return true;
      } else {
        context.dispatch(doSignOut());
        return false;
      }
    } else {
      return false;
    }
  };
}
/**
 *@exports useIsAuthenticated
 */
export default useIsAuthenticated;
