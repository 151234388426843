import '../styles/globals.css';
import theme from '../styles/ThemeConfig';

import Head from 'next/head';
import { useEffect } from 'react';

import { CacheProvider, EmotionCache } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import createCache from '@emotion/cache';

import type { ReactElement, ReactNode } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { Settings } from 'luxon';

import {
  WithAuthentication,
  WithAuthProvider,
} from '../components/Authentication';
import {
  GraphqlClientContextProvider,
  ContextProvider,
} from '@bamboard/app-context';
import Script from 'next/script';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  emotionCache: EmotionCache;
};

Settings.defaultZone = 'Australia/Perth';

const clientSideEmotionCache = createEmotionCache();

function App({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: AppPropsWithLayout) {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    jssStyles?.parentElement?.removeChild(jssStyles);
  }, []);

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);
  const Content = getLayout(<><Component {...pageProps} />
    <Script
      async
      type="text/javascript"
      src="https://bambooblockchain.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-3ddrgv/b/7/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-GB&collectorId=8f0be3c0"
    ></Script>
    {/* do not break format dangerouslySetInnerHTML otherwise CSP will block the content*/}
    <Script id="add-collector" type="text/javascript" dangerouslySetInnerHTML={{
      __html: `window.ATL_JQ_PAGE_PROPS = {
      triggerFunction: function (showCollectorDialog) {
        window.showCollectorDialog = showCollectorDialog;
      }
    }`
    }}>
    </Script></>);

  const configs = [
    {
      key: 'AU',
      endpoint: process.env.NEXT_PUBLIC_AU_API_ENDPOINT,
    },
    {
      key: 'US',
      endpoint: process.env.NEXT_PUBLIC_US_API_ENDPOINT,
    },
  ];

  return (
    <GraphqlClientContextProvider configs={configs}>
      <ContextProvider>
        <CacheProvider value={emotionCache}>
          <Head>
            <title>Bamboo Dashboard</title>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
          </Head>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {Content}
          </ThemeProvider>
        </CacheProvider>
      </ContextProvider>
    </GraphqlClientContextProvider>
  );
}

export default dynamic(
  () =>
    Promise.resolve(
      (() => {
        const Authenticated = WithAuthentication(App);
        return WithAuthProvider(Authenticated);
      })()
    ),
  { ssr: false }
);

function createEmotionCache() {
  return createCache({
    key: 'css',
    prepend: true,
  });
}
