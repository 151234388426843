import * as React from 'react';
import AuthContext from '../AuthContext';
import { AuthStateUserObject } from '../types';

function useAuthUser(): () => AuthStateUserObject | null {
  const context = React.useContext(AuthContext);
  if (context === null) {
    throw new Error(
      'Auth Provider is missing. ' + 'Please add the AuthProvider before Router'
    );
  }
  return () => {
    return context.authState.auth ? context.authState.userState : null;
  };
}

export default useAuthUser;
