import { Components } from "@mui/material/styles";

export const componentOverrides: Components = {
  // MuiCssBaseline: {
  //   styleOverrides: {
  //     html: {
  //       scrollBehavior: "smooth",
  //     },
  //     body: {
  //       padding: 0,
  //       margin: 0,
  //       fontFamily: "Nexa, sans-serif",
  //     },
  //     strong: {
  //       fontFamily: "NexaBold, sans-serif",
  //     },
  //     a: {
  //       color: "inherit",
  //       textDecoration: "none",

  //       "&:hover, &:focus": {
  //         textDecoration: "underline",
  //       },
  //     },
  //     ul: {
  //       '&[data-bullet="false"]': {
  //         listStyle: "none",
  //         padding: 0,
  //       },
  //     },
  //   },
  // },
  // MuiLink: {
  //   defaultProps: {
  //     underline: "hover",
  //   },
  // },
  // MuiMobileStepper: {
  //   styleOverrides: {
  //     root: {
  //       margin: "2em -1.5em -1em",
  //       borderRadius: "0 0 1em 1em",
  //     },
  //   },
  // },
  // MuiTypography: {
  //   defaultProps: {
  //     variantMapping: {
  //       body2: "span",
  //     },
  //   },
  // },
  // MuiTooltip: {
  //   styleOverrides: {
  //     tooltip: {
  //       backgroundColor: "#616161",
  //       padding: "1em",
  //     },
  //   },
  // },
  // MuiButton: {
  //   styleOverrides: {
  //     root: {
  //       textTransform: "none",
  //       minWidth: "none",
  //     },
  //     sizeLarge: {
  //       height: "3.5rem",
  //     },
  //     contained: {
  //       backgroundColor: COLOURS.primary.main,
  //       color: "#FFF",

  //       "&.white": {
  //         backgroundColor: "#FFF",
  //         color: COLOURS.primary.main,
  //       },
  //     },
  //   },
  // },
  // MuiListItemIcon: {
  //   styleOverrides: {
  //     root: {
  //       minWidth: "3em",
  //       alignItems: "center",
  //     },
  //   },
  // },
  // MuiListItemButton: {
  //   styleOverrides: {
  //     root: {
  //       "& .MuiBadge-root": {
  //         width: "100%",

  //         "& .MuiBadge-badge": {
  //           top: "50%",
  //           right: "0.5em",
  //           fontSize: "0.9em",
  //           padding: "0.5em",
  //           fontWeight: "bold",
  //           height: "2em",
  //           width: "2em",
  //           borderRadius: "50%",
  //         },
  //       },
  //     },
  //   },
  // },
  // MuiRadio: {
  //   styleOverrides: {
  //     root: {
  //       "& .MuiSvgIcon-root": {
  //         color: COLOURS.primary.main,
  //       },
  //     },
  //   },
  // },
  // MuiFormControlLabel: {
  //   styleOverrides: {
  //     root: {
  //       "&.with-card span:nth-child(2)": {
  //         width: "100%",
  //         marginBottom: "0.5em",
  //       },
  //     },
  //   },
  // },
  // MuiTable: {
  //   styleOverrides: {
  //     root: {
  //       borderColor: "pink",
  //     },
  //   },
  // },
  // MuiAlert: {
  //   styleOverrides: {
  //     message: {
  //       textAlign: "left",
  //     },
  //   },
  // },
  // MuiSvgIcon: {
  //   styleOverrides: {
  //     root: {
  //       zIndex: 1,
  //       // color: "#3C3C3C",

  //       "&.MuiSvgIcon-colorSuccess": {
  //         color: COLOURS.success.main,
  //       },
  //       "&.MuiSvgIcon-secondary": {
  //         color: "#0D6EFDC9",
  //       },
  //     },
  //   },
  // },
  // MuiFormHelperText: {
  //   styleOverrides: {
  //     root: {
  //       "&.Mui-error": {
  //         fontSize: "0.7em",
  //       },
  //     },
  //   },
  // },
  // MuiInputBase: {
  //   styleOverrides: {
  //     root: {
  //       "&:hover, &:focus, & fieldset": {
  //         borderColor: "#757575 !important",
  //       },
  //       "&.Mui-error fieldset": {
  //         borderColor: "#d32f2f !important",
  //       },
  //     },
  //     input: {
  //       zIndex: 1,
  //     },
  //   },
  // },
  // MuiInputLabel: {
  //   styleOverrides: {
  //     root: {
  //       color: "#777",
  //       fontSize: "0.9em",
  //       transform: "translate(0.8em, 0.8em) scale(1)",

  //       "&.Mui-focused, &[data-shrink=true]": {
  //         transform: "translate(0.8em, -0.6em) scale(0.75)",
  //       },
  //     },
  //   },
  // },
  // MuiOutlinedInput: {
  //   styleOverrides: {
  //     input: {
  //       padding: "0.8em 0.5em",
  //     },
  //     colorSecondary: {
  //       "& fieldset": {
  //         backgroundColor: "#FFF",
  //       },
  //     },
  //   },
  // },
  // MuiCheckbox: {
  //   styleOverrides: {
  //     colorPrimary: {
  //       color: COLOURS.text.primary,

  //       "&.Mui-checked": {
  //         color: COLOURS.primary.main,
  //       },
  //     },
  //     colorSecondary: {
  //       color: "#FFF",

  //       "&.Mui-checked": {
  //         color: "#FFF",
  //       },
  //     },
  //   },
  // },
};
